import React, { useEffect, useRef, useState } from "react";

function LottieAnimation({ root, playerRef, id, speed }) {
  const innerPlayerRef = useRef();
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        try {
          const [entry] = entries;
          // Check if the element is in the viewport and start playing
          if (entry.isIntersecting) {
            // restart the animation
            innerPlayerRef.current.play();
          } else if (innerPlayerRef.current) {
            // pause the animation
            innerPlayerRef.current.seek(0);
            innerPlayerRef.current.pause();
          }
        } catch (e) {
          setErrorLoading(true);
        }
      },
      {
        root: null, // observing for viewport
        rootMargin: "0px",
        threshold: 0.3, // Adjust this value according to your needs
      },
    );

    try {
      innerPlayerRef.current?.addEventListener("ready", () =>
        observer.observe(playerRef.current),
      );
    } catch (e) {}
  }, [innerPlayerRef.current, playerRef.current, root]);

  return (
    <>
      <script
        src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs"
        type="module"
      ></script>
      <dotlottie-player
        ref={innerPlayerRef}
        className={
          "relative mx-auto md:w-[530px] h-auto " +
          (playerRef.current ? "opacity-100" : "opacity-0")
        }
        src={"https://lottie.host/" + id + ".json"}
        background="transparent"
        speed={speed}
        direction="1"
        playMode="normal"
        autoplay={playerRef.current} // Turn off autoplay to manage play state via IntersectionObserver
      ></dotlottie-player>
    </>
  );
}

export default LottieAnimation;
