import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { Archivo, Source_Code_Pro } from "next/font/google";

const archivo = Archivo({
  subsets: ["latin"],
  variable: "--font-archivo",
  fallback: ["Archivo", "system-ui"],
});

const sourceCodePro = Source_Code_Pro({
  subsets: ["latin"],
  variable: "--font-source-code-pro",
  fallback: ["Source Code Pro", "Courier", "monospace"],
});

export default function Modal(props) {
  const cancelButtonRef = useRef(null);

  return (
    <>
      {props.button ? props.button : <></>}
      <Transition.Root
        id="dialog-root"
        className={`${archivo.variable} font-sans transition-root ${sourceCodePro.variable}`}
        show={props.open ? true : false}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative dialog-root"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              id="dialog-background"
              className="fixed inset-0 dialog-background bg-black bg-opacity-50 z-30 transition-opacity"
            />
          </Transition.Child>

          <div
            id="dialog-foreground"
            className="fixed dialog-foreground inset-0 overflow-visible z-40"
          >
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 -mt-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  id="dialog-panel"
                  className={
                    "relative transform rounded-sm bg-white border-neutral-500 border text-left shadow-xl transition-all sm:my-8 sm:w-full " +
                    (props.width ? "sm:max-w-" + props.width : "sm:max-w-lg")
                  }
                >
                  <div className="w-full h-8 bg-black justify-end text-right mb-2 select-none">
                    <button
                      type="button"
                      className="text-white text-2xl pt-0.5 font-medium align-middle self-center leading-none mr-2 outline-none border-none focus:outline-none focus:ring-none"
                      onClick={props.close}
                    >
                      ×
                    </button>
                  </div>
                  {props.content}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
