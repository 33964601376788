import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useRouter } from "next/router";
import { useAuth } from "util/auth";
import {
  IconArrowRight,
  IconCloudStar,
  IconDeviceDesktopSearch,
} from "@tabler/icons-react";
import Link from "next/link";
import { CloudIcon } from "@heroicons/react/24/solid";

export default function Hero() {
  const auth = useAuth();
  const router = useRouter();
  const [email, setEmail] = useState("");

  const onEmailSubmit = (e) => {
    e.preventDefault();
    if (!auth.user) {
      router.push(`/auth/signup?email=${email}`);
    } else {
      router.push(`/dashboard`);
    }
  };

  return (
    <div className="pb-8 sm:pb-12 lg:pt-12 lg:pb-24">
      <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-24">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl grid-cols-9 lg:px-8">
          <div className="col-span-7 z-30">
            <div>
              <div>
                <Link
                  href="/blog/rule-engine-guardrails"
                  className="space-x-4 hidden sm:inline-flex group"
                >
                  <span className="rounded-md bg-neutral-50 border border-neutral-400 border-opacity-20 px-3 py-0.5 text-lg font-medium text-neutral-500">
                    What's new
                  </span>
                  <span className="inline-flex items-center space-x-1 text-xl font-medium text-neutral-500 hover:text-black hover:underline underline-offset-2">
                    <span>Customizable end-user experiences</span>
                    <IconArrowRight className="h-5 w-5" aria-hidden="true" />
                  </span>
                </Link>
              </div>
              <div className="mt-6">
                <h1 className="text-5xl font-semibold tracking-tight text-black sm:text-7xl lg:bg-opacity-20">
                  Make decisions anywhere
                </h1>
                <p className="mt-4 sm:mt-6 sm:text-2xl text-xl text-black bg-white lg:bg-opacity-20">
                  Rulebricks helps teams add remarkably powerful decision-making
                  capabilities to any automated business process.
                </p>
              </div>
              <form action="#" className="sm:mt-10 mt-6 sm:flex sm:w-full z-10">
                <div className="mt-2 rounded-md sm:mt-0 inline-flex w-full max-w-sm sm:bg-transparent sm:w-auto">
                  <a
                    href="https://calendly.com/prefix-software/rulebricks"
                    type="submit"
                    target="_blank"
                    className="inline-flex whitespace-nowrap h-12 items-center justify-center w-[14.5rem] sm:w-fit sm:mr-2 mr-1 rounded-md border bg-black text-lg sm:text-xl text-white hover:bg-gray-50 hover:text-black hover:border border-black duration-150 sm:px-4"
                  >
                    <IconDeviceDesktopSearch className="hidden sm:inline-flex h-full w-5 mr-2.5 self-center align-middle" />
                    Get a demo
                  </a>
                  <button
                    type="submit"
                    className="inline-flex whitespace-nowrap h-12 items-center sm:mt-0 w-full -mx-px sm:mx-0 sm:w-fit justify-center rounded-md border-l border-neutral-800 sm:border bg-black text-lg sm:text-xl text-white hover:bg-gray-50 hover:text-black hover:border sm:border-black duration-150 sm:px-4"
                    onClick={onEmailSubmit}
                  >
                    Start building rules
                    <ArrowRightIcon
                      className="h-full hidden sm:flex w-6 ml-2 self-center align-middle"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </form>
            </div>
            <div className="mt-6 sm:mt-8 font-light saturate-0 divide-x divide-neutral-300 space-x-4 w-fit">
              <div className="text-lg text-black bg-white lg:bg-opacity-20 align-middle inline-flex sm:ml-0 ml-0.5">
                <span className="text-neutral-500">Backed by</span>
                <img
                  className="inline-block self-center h-6 w-auto ml-1.5"
                  src="static/images/yc-logo.png"
                  alt="Y Combinator"
                />
              </div>
              <div className="text-lg text-black bg-white lg:bg-opacity-20 align-middle inline-flex pl-4">
                <img
                  className="inline-block self-center h-6 w-auto mr-2"
                  src="static/images/socbadge.png"
                  alt="SOC 2 Type 2 Certified"
                />
                <span className="text-neutral-500">SOC 2 Type II</span>
              </div>
              <div className="text-lg text-black bg-white lg:bg-opacity-20 align-middle hidden md:inline-flex pl-4">
                <CloudIcon className="inline-block text-neutral-500 self-center h-6 w-auto mr-2" />
                <span className="text-neutral-500">500+ Cloud Users</span>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="sm:relative lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="relative lg:absolute z-0 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:pl-12">
              <img
                className="w-full lg:hidden pt-12 my-6 -translate-x-12 sm:translate-x-0 object-fill transform lg:h-full lg:w-auto lg:max-w-none"
                src="static/images/cover.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
