import { useRef } from "react";
import LottieAnimation from "./LottieAnimation";
import {
  IconBrandNodejs,
  IconBrandTypescript,
  IconBrandPython,
  IconBrandGolang,
  IconPlus,
  IconDots,
  IconCoffee,
  IconBrandCSharp,
  IconDiamond,
} from "@tabler/icons-react";

const transferFeatures = [
  {
    id: 1,
    name: "Accelerate time to market",
    description:
      "Rulebricks enables domain experts and process owners to make changes to key decisions without needing to write code or wrangle with complicated workflow UIs.",
    icon: "/static/images/icons/automation tools.svg",
  },
  {
    id: 2,
    name: "Empower domain experts",
    description:
      "Enable teammates unfamiliar with your technologies to understand and change how your business makes particular decisions without having to mess with complicated scripts or automation tools.",
    icon: "/static/images/icons/edit.svg",
  },
  {
    id: 3,
    name: "Gain valuable new insights",
    description:
      "Capture a rich history of how decisions in your business are being made, including the inputs, the rule that was applied, and the exact conditions that were met.",
    icon: "/static/images/icons/understand.svg",
  },
];
const communicationFeatures = [
  {
    id: 1,
    name: "Connect to existing systems",
    description:
      "Build rule flows on Rulebricks to integrate rules with your business data, or use our Zapier app, and start building smarter, faster business processes without writing a single line of code.",
    icon: "/static/images/icons/platform integrations.svg",
  },
  {
    id: 2,
    name: "Powerful developer SDKs",
    description:
      "Publish rules and send JSON data to a straightforward rule solver API endpoint in 2 lines of code. Leverage powerful utilities to programmatically invite users, manage access, construct rules, and upload tests.",
    icon: "/static/images/icons/developer API.svg",
  },
];

const useCases = [
  "Offer Discounts & Promotions",
  "Approve Loans",
  "Create Surveys & Quizzes",
  "Calculate Quotes & Eligibility",
  "Route Prospects & Support Tickets",
  "Assess Bid Requests",
  "Reorder Inventory",
  "Detect Fraud & Misuse",
  "Classify & Organize Documents",
  "Approve Purchase Orders",
  "Renew Contracts",
  "Manage AI Parameters",
];

const colors = [
  "border-sky-400/20 bg-sky-300/70 text-sky-800 shadow-sky-400/30",
  "border-orange-400/20 bg-orange-300/70 text-orange-800 shadow-orange-400/30",
  "border-rose-400/20 bg-rose-300/70 text-rose-800 shadow-rose-400/30",
  "border-lime-400/20 bg-lime-300/70 text-lime-800 shadow-lime-400/30",
  "border-violet-400/20 bg-violet-300/70 text-violet-800 shadow-violet-400/30",
  "border-cyan-400/20 bg-cyan-300/70 text-cyan-800 shadow-cyan-400/30",
  "border-amber-400/20 bg-amber-300/70 text-amber-800 shadow-amber-400/30",
  "border-emerald-400/20 bg-emerald-300/70 text-emerald-800 shadow-emerald-400/30",
  "border-red-400/20 bg-red-300/70 text-red-800 shadow-red-400/30",
  "border-pink-400/20 bg-pink-300/70 text-pink-800 shadow-pink-400/30",
  "border-indigo-400/20 bg-indigo-300/70 text-indigo-800 shadow-indigo-400/30",
  "border-teal-400/20 bg-teal-300/70 text-teal-800 shadow-teal-400/30",
];

export default function HomeSmallImages() {
  const parentRef = useRef(null);
  const player1Ref = useRef(null);
  const player2Ref = useRef(null);

  return (
    <>
      <div
        ref={parentRef}
        className="w-full saturate-[0.7] pt-4 sm:p-4 sm:pb-0 bg-neutral-50 border-neutral-300 border-y"
      >
        <div className="w-full pb-4 inline-flex flex-nowrap overflow-hidden sm:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
          <ul className="flex items-center justify-center md:justify-start [&_li]:mx-0.5 [&_img]:max-w-none animate-infinite-scroll">
            {useCases.map((useCase, index) => (
              <li key={index}>
                <div className="text-lg whitespace-nowrap p-2">
                  <span
                    className={
                      "p-2 px-4 shadow-xl rounded-md font-serif  opacity-70 border-2 " +
                      colors[index]
                    }
                  >
                    {useCase}
                  </span>
                </div>
              </li>
            ))}
          </ul>
          <ul
            className="flex items-center justify-center md:justify-start [&_li]:mx-0.5 [&_img]:max-w-none animate-infinite-scroll"
            aria-hidden="true"
          >
            {useCases.map((useCase, index) => (
              <li key={index}>
                <div className="text-lg whitespace-nowrap p-2">
                  <span
                    className={
                      "p-2 px-4 shadow-xl rounded-md font-serif opacity-70 border-2 " +
                      colors[index]
                    }
                  >
                    {useCase}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="overflow-hidden z-20 bg-neutral-100 border-neutral-300 sm:pb-16 pt-4 md:pt-0">
        <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
          <div className="relative mt-12 md:mt-20 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="relative">
              <h3 className="text-4xl font-semibold tracking-tight text-neutral-900 sm:text-4xl">
                Separate rules from runtimes
              </h3>
              <p className="mt-5 text-xl text-neutral-500">
                Many workflow builders and automation tools have support for
                adding logic to your processes, but get complicated quick.
              </p>
              <p className="mt-3 text-xl text-neutral-500">
                Rulebricks separates decision-making logic from your workflows,
                giving your team a clear visual interface to manage the changing
                rules that drive your business.
              </p>

              <dl className="mt-10 space-y-10">
                {transferFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-transparent border-0 border-black text-black">
                        <img
                          src={item.icon}
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-xl font-medium leading-6 text-neutral-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-neutral-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div
              ref={player1Ref}
              className="relative mx-4 mt-16"
              aria-hidden="true"
            >
              <LottieAnimation
                id={"f3a82030-fc19-4250-a77a-f45c576f61b9/byjnDNWddt"}
                root={parentRef.current}
                playerRef={player1Ref}
              />
            </div>
          </div>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="relative">
              <h3 className="text-4xl font-semibold tracking-tight text-neutral-900 sm:text-4xl">
                Your fastest integration yet
              </h3>
              <p className="mt-5 text-xl text-neutral-500">
                Connect our rule engine to your business for completely free and
                with just a few clicks, and immediately access smarter workflows
                and increased visibility into your processes.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-transparent border-0 border-black text-black">
                        <img
                          src={item.icon}
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-xl font-medium leading-6 text-neutral-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-neutral-500">
                      {item.description}
                    </dd>
                    {item.name === "Powerful developer SDKs" && (
                      <div className="mt-6 ml-16 gap-6 inline-flex flex-wrap">
                        <a
                          href="/docs/api-reference#nodejstypescript"
                          className="opacity-[0.19] hover:opacity-100 hover:text-blue-600 duration-150"
                        >
                          <IconBrandTypescript
                            strokeWidth={1.3}
                            className="h-12 w-12 inline-block self-center"
                          />
                        </a>
                        <a
                          href="/docs/api-reference#c"
                          className="opacity-[0.19] hover:opacity-100 hover:text-purple-600 duration-150"
                        >
                          <IconBrandCSharp
                            strokeWidth={1.3}
                            className="h-12 w-12 inline-block self-center"
                          />
                        </a>
                        <a
                          href="/docs/api-reference#python"
                          className="opacity-[0.19]  hover:opacity-100 hover:text-yellow-600 duration-150"
                        >
                          <IconBrandPython
                            strokeWidth={1.3}
                            className="h-12 w-12 inline-block self-center"
                          />
                        </a>
                        <a
                          href="/docs/api-reference#java"
                          className="opacity-[0.19]  hover:opacity-100 hover:text-amber-600 duration-150"
                        >
                          <IconCoffee
                            strokeWidth={1.3}
                            className="h-12 w-12 inline-block self-center"
                          />
                        </a>
                        <a
                          href="/docs/api-reference#ruby"
                          className="opacity-[0.19]  hover:opacity-100 hover:text-red-600 duration-150"
                        >
                          <IconDiamond
                            strokeWidth={1.3}
                            className="h-12 w-12 inline-block self-center"
                          />
                        </a>
                        <a
                          href="/docs/api-reference#go"
                          className="opacity-[0.19]  hover:opacity-100 hover:text-sky-400 duration-150"
                        >
                          <IconBrandGolang
                            strokeWidth={1.3}
                            className="h-12 w-12 inline-block self-center"
                          />
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </dl>
            </div>

            <div
              className="relative mx-4 mt-10 lg:mt-0"
              ref={player2Ref}
              aria-hidden="true"
            >
              <LottieAnimation
                id={"11414239-e6f0-4122-89fb-235edc031934/SnezGGpqjX"}
                root={parentRef.current}
                playerRef={player2Ref}
                speed={"2"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
